export const ROUTE_CONSTANTS = {
  ROOT: {
    RELATIVE: "/",
    ABSOLUTE: "/",
  },
  ROOT_STAR: {
    RELATIVE: "/*",
    ABSOLUTE: "/*",
  },
  PUBLIC_ROUTES: {
    NOT_PERMITTED: {
      RELATIVE: "not-permitted",
      ABSOLUTE: "/not-permitted",
    },
    STATIC_PAGES: {
      ROOT: {
        RELATIVE: ":name/:language",
        ABSOLUTE: "/:name/:language",
      },
      GET_BY_DATA: (name, language) => {
        return {
          RELATIVE: `/${name}/${language}`,
          ABSOLUTE: `/${name}/${language}`,
        };
      },
    },
  },
  AUTH: {
    ROOT: {
      RELATIVE: "auth",
      ABSOLUTE: "/auth",
    },
    LOGIN: {
      RELATIVE: "login",
      ABSOLUTE: "/auth/login",
    },
    RESET_PASSWORD: {
      RELATIVE: "reset-password",
      ABSOLUTE: "/auth/reset-password",
    },
    FORGOT_PASSWORD: {
      RELATIVE: "forgot-password",
      ABSOLUTE: "/auth/forgot-password",
    },
  },
  DASHBOARD: {
    ROOT: {
      RELATIVE: "dashboard",
      ABSOLUTE: "/dashboard",
    },
    WELCOME: {
      ROOT: {
        RELATIVE: "welcome",
        ABSOLUTE: "/dashboard/welcome",
      },
    },
    USERS: {
      ROOT: {
        RELATIVE: "users",
        ABSOLUTE: "/dashboard/users",
      },
      PROFILE: {
        RELATIVE: "me",
        ABSOLUTE: "/dashboard/me/",
      },
      ADMINS: {
        RELATIVE: "users/admins",
        ABSOLUTE: "/dashboard/users/admins",
      },
      CUSTOMERS: {
        RELATIVE: "users/customers",
        ABSOLUTE: "/dashboard/users/customers",
      },
      PRO: {
        ROOT: {
          RELATIVE: "users/pros",
          ABSOLUTE: "/dashboard/users/pros",
        },
      },
      DETAILS: {
        RELATIVE: "users/:userType/:id",
        ABSOLUTE: "/dashboard/users/:userType/:id",
      },
      GET_BY_DATA: (id, userType) => {
        return {
          RELATIVE: `users/:userType/:id`,
          ABSOLUTE: `/dashboard/users/${userType}/${id}`,
        };
      },
    },
    CATALOG: {
      ROOT: {
        RELATIVE: "catalog",
        ABSOLUTE: "/dashboard/catalog",
      },
      CATEGORY: {
        ROOT: {
          RELATIVE: "catalog/category",
          ABSOLUTE: "/dashboard/catalog/category",
        },
        ADD_NEW: {
          RELATIVE: "catalog/category/add-new",
          ABSOLUTE: "/dashboard/catalog/category/add-new",
        },
        UPDATE: {
          RELATIVE: "catalog/category/:id/update",
          ABSOLUTE: "/dashboard/catalog/category/:id/update",
          EDIT_BY_DATA: (id) => {
            return {
              RELATIVE: `catalog/category/:id/update`,
              ABSOLUTE: `/dashboard/catalog/category/${id}/update`,
            };
          },
        },
        REORDER: {
          RELATIVE: "catalog/category/reorder",
          ABSOLUTE: "/dashboard/catalog/category/reorder",
        },
      },
      TYPE: {
        ROOT: {
          RELATIVE: "catalog/type",
          ABSOLUTE: "/dashboard/catalog/type",
        },
        ADD_NEW: {
          RELATIVE: "catalog/type/add-new",
          ABSOLUTE: "/dashboard/catalog/type/add-new",
        },
        UPDATE: {
          RELATIVE: "catalog/type/:id/update",
          ABSOLUTE: "/dashboard/catalog/type/:id/update",
          EDIT_BY_DATA: (id) => {
            return {
              RELATIVE: `catalog/type/:id/update`,
              ABSOLUTE: `/dashboard/catalog/type/${id}/update`,
            };
          },
        },
      },
    },
    QUESTIONNAIRE: {
      ROOT: {
        RELATIVE: "questionnaire",
        ABSOLUTE: "/dashboard/questionnaire",
      },
      ADD_NEW: {
        RELATIVE: "questionnaire/add-new",
        ABSOLUTE: "/dashboard/questionnaire/add-new",
      },
      UPDATE: {
        RELATIVE: "questionnaire/:id/update",
        ABSOLUTE: "/dashboard/questionnaire/:id/update",
        EDIT_BY_DATA: (id) => {
          return {
            RELATIVE: `questionnaire/:id/update`,
            ABSOLUTE: `/dashboard/questionnaire/${id}/update`,
          };
        },
      },
      QUESTIONS: {
        ROOT: {
          RELATIVE: "questionnaire/:id/questions",
          ABSOLUTE: "/dashboard/questionnaire/:id/questions",
          GET_BY_DATA: (id) => {
            return {
              RELATIVE: `questionnaire/:id/questions`,
              ABSOLUTE: `/dashboard/questionnaire/${id}/questions`,
            };
          },
        },
        REORDER: {
          RELATIVE: "questionnaire/:id/questions/reorder",
          ABSOLUTE: "/dashboard/questionnaire/:id/questions/reorder",
          REORDER_BY_DATA: (id) => {
            return {
              RELATIVE: `questionnaire/:id/questions/reorder`,
              ABSOLUTE: `/dashboard/questionnaire/${id}/questions/reorder`,
            };
          },
        },
        ADD_NEW: {
          RELATIVE: "questionnaire/:id/questions/add-new",
          ABSOLUTE: "/dashboard/questionnaire/:id/questions/add-new",
          ADD_NEW_BY_DATA: (id) => {
            return {
              RELATIVE: `questionnaire/:id/questions/add-new`,
              ABSOLUTE: `/dashboard/questionnaire/${id}/questions/add-new`,
            };
          },
        },
        UPDATE: {
          RELATIVE: "questionnaire/:id/questions/:questionId/update",
          ABSOLUTE: "/dashboard/questionnaire/:id/questions/:questionId/update",
          EDIT_BY_DATA: (id, questionId) => {
            return {
              RELATIVE: `questionnaire/:id/questions/:questionId/update`,
              ABSOLUTE: `/dashboard/questionnaire/${id}/questions/${questionId}/update`,
            };
          },
        },
        OPTIONS: {
          ROOT: {
            RELATIVE: "questionnaire/:id/questions/:questionId/options",
            ABSOLUTE:
              "/dashboard/questionnaire/:id/questions/:questionId/options",
            GET_BY_DATA: (id, questionId) => {
              return {
                RELATIVE: `questionnaire/:id/questions/:questionId/options`,
                ABSOLUTE: `/dashboard/questionnaire/${id}/questions/${questionId}/options`,
              };
            },
          },
          REORDER: {
            RELATIVE: "questionnaire/:id/questions/:questionId/options/reorder",
            ABSOLUTE:
              "/dashboard/questionnaire/:id/questions/:questionId/options/reorder",
            REORDER_BY_DATA: (id, questionId) => {
              return {
                RELATIVE: `questionnaire/:id/questions/:questionId/options/reorder`,
                ABSOLUTE: `/dashboard/questionnaire/${id}/questions/${questionId}/options/reorder`,
              };
            },
          },
          ADD_NEW: {
            RELATIVE: "questionnaire/:id/questions/:questionId/options/add-new",
            ABSOLUTE:
              "/dashboard/questionnaire/:id/questions/:questionId/options/add-new",
            ADD_NEW_BY_DATA: (id, questionId) => {
              return {
                RELATIVE: `questionnaire/:id/questions/:questionId/options/add-new`,
                ABSOLUTE: `/dashboard/questionnaire/${id}/questions/${questionId}/options/add-new`,
              };
            },
          },
          UPDATE: {
            RELATIVE:
              "questionnaire/:id/questions/:questionId/options/:optionId/update",
            ABSOLUTE:
              "/dashboard/questionnaire/:id/questions/:questionId/options/:optionId/update",
            EDIT_BY_DATA: (id, questionId, optionId) => {
              return {
                RELATIVE: `questionnaire/:id/questions/:questionId/options/:optionId/update`,
                ABSOLUTE: `/dashboard/questionnaire/${id}/questions/${questionId}/options/${optionId}/update`,
              };
            },
          },
        },
      },
    },
    BOOKING: {
      ROOT: {
        RELATIVE: "booking",
        ABSOLUTE: "/dashboard/booking",
      },
      BOOKREQUESTS: {
        ROOT: {
          RELATIVE: "booking/requests",
          ABSOLUTE: "/dashboard/booking/requests",
        },
        DETAILS: {
          RELATIVE: "booking/requests/:id",
          ABSOLUTE: "/dashboard/booking/requests/:id",
          GET_BY_DATA: (id) => {
            return {
              RELATIVE: `booking/requests/:id`,
              ABSOLUTE: `/dashboard/booking/requests/${id}`,
            };
          },
        },
      },
      BIDS: {
        ROOT: {
          RELATIVE: "booking/bids",
          ABSOLUTE: "/dashboard/booking/bids",
        },
        DETAILS: {
          RELATIVE: "booking/bids/:id",
          ABSOLUTE: "/dashboard/booking/bids/:id",
          GET_BY_DATA: (id) => {
            return {
              RELATIVE: `booking/bids/:id`,
              ABSOLUTE: `/dashboard/booking/bids/${id}`,
            };
          },
        },
      },
    },
    BIDPRICING: {
      ROOT: {
        RELATIVE: "bid-pricing",
        ABSOLUTE: "/dashboard/bid-pricing",
      },
      PAYMENTHISTORY: {
        ROOT: {
          RELATIVE: "bid-pricing/payment-history",
          ABSOLUTE: "/dashboard/bid-pricing/payment-history",
        },
        DETAILS: {
          RELATIVE: "bid-pricing/payment-history/:id",
          ABSOLUTE: "/dashboard/bid-pricing/payment-history/:id",
        },
        GET_BY_DATA: (id) => {
          return {
            RELATIVE: `bid-pricing/payment-history/:id`,
            ABSOLUTE: `/dashboard/bid-pricing/payment-history/${id}`,
          };
        },
      },
    },
    SYSTEM: {
      ROOT: {
        RELATIVE: "system",
        ABSOLUTE: "/dashboard/system",
      },
      STATIC_PAGES: {
        ROOT: {
          RELATIVE: "system/static-pages",
          ABSOLUTE: "/dashboard/system/static-pages",
        },
      },
      WALLETLIMIT: {
        ROOT: {
          RELATIVE: "system/wallet-limit",
          ABSOLUTE: "/dashboard/system/wallet-limit",
        },
      },
      ADMINEMAIL: {
        ROOT: {
          RELATIVE: "system/admin-email",
          ABSOLUTE: "/dashboard/system/admin-email",
        },
      },
      INAPPSUBSCRIPTION: {
        ROOT: {
          RELATIVE: "system/in-app-subscription",
          ABSOLUTE: "/dashboard/system/in-app-subscription",
        },
      },
      ADMINISTRATION: {
        ROOT: {
          RELATIVE: "system/administration",
          ABSOLUTE: "/dashboard/system/administration",
        },
      },
      UNIY_MEASUREMENT: {
        ROOT: {
          RELATIVE: "system/units",
          ABSOLUTE: "/dashboard/system/units",
        },
        ADD_NEW: {
          RELATIVE: "system/units/add-new",
          ABSOLUTE: "/dashboard/system/units/add-new",
        },
        UPDATE: {
          RELATIVE: "system/units/:id/update",
          ABSOLUTE: "/dashboard/system/units/:id/update",
          EDIT_BY_DATA: (id) => {
            return {
              RELATIVE: `system/units/:id/update`,
              ABSOLUTE: `/dashboard/system/units/${id}/update`,
            };
          },
        },
      },
      SMS_GATEWAY: {
        ROOT: {
          RELATIVE: "system/sms-gateway",
          ABSOLUTE: "/dashboard/system/sms-gateway",
        },
      },
    },
  },
};
