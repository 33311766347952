import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { adminService } from "src/api/services/admin";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import Footer from "src/components/layouts/AccentSidebarLayout/Footer";
import { PERMISSIONS } from "src/constants/permissions";
import { usePermission } from "src/contexts/GlobalContext";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import DynamicForm from "./components/From";
import PageHeader from "./components/PageHeader";

const SmsGateway = () => {
  const [loading, setLoading] = useState(false);
  const [smsProvider, setSmsProvider] = useState(null);
  const { toast } = useToast();
  const permissions = usePermission();

  const getSmsProvider = async () => {
    if (!permissions[PERMISSIONS.Get_Setting]) return;
    try {
      const response = await adminService.getSettings("admin:smsProvider");

      const smsProvider = response?.data?.data?.value;
      setSmsProvider(smsProvider);
    } catch (error) {
      toast(errorMessage(error), "error");
    }
  };
  const handleSubmit = async (formData) => {
    if (!permissions[PERMISSIONS.Update_Settings]) return;
    try {
      setLoading(true);
      await adminService.updateSettings("admin:smsProvider", {
        value: formData.smsProvider,
      });
      toast("Sms provider is changed successfully.");
    } catch (error) {
      console.log("error", error);
      toast(errorMessage(error), "error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSmsProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "91vh",
        width: "100%",
        maxWidth: "100%",
        position: "relative",
      }}
    >
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid
        sx={{
          mt: { xs: 1, sm: 1, md: 3, lg: 8, xl: 8 },
          pl: { xs: 5, sm: 5, md: 9, lg: 9, xl: 9 },
          pr: { xs: 1, sm: 1, md: 9, lg: 9, xl: 9 },
        }}
        container
        direction="row"
        justifyContent="flex-start"
        spacing={4}
      >
        <StyledWrapperCard>
          <Typography variant="h5" component="h5" gutterBottom mb={4}>
            Sms provider control
          </Typography>
          {smsProvider ? (
            <>
              <Grid item xs={12}>
                <DynamicForm
                  onSubmitCustomAction={handleSubmit}
                  initialValues={{
                    smsProvider: smsProvider,
                  }}
                  fields={(errors, control) => (
                    <Grid
                      container
                      spacing={2}
                      display="flex"
                      alignItems="center"
                    >
                      <Grid item xs={12} fullWidth>
                        <Grid
                          container
                          spacing={3}
                          display="flex"
                          alignItems="center"
                        >
                          <Grid item lg={6} md={6} xl={6} xs={6}>
                            <Box display="flex" alignItems="center">
                              <FormControl>
                                <Controller
                                  name="smsProvider"
                                  control={control}
                                  rules={{
                                    required: "Please select a sms provider",
                                  }}
                                  render={({ field }) => (
                                    <RadioGroup {...field}>
                                      <FormControlLabel
                                        value="twilio"
                                        control={<Radio />}
                                        label="Twilio"
                                      />
                                      <FormControlLabel
                                        value="kwtSms"
                                        control={<Radio />}
                                        label="KwtSms"
                                      />
                                    </RadioGroup>
                                  )}
                                />
                              </FormControl>
                            </Box>
                            <Box>
                              {errors?.smsProvider && (
                                <Typography color="red">
                                  {errors?.smsProvider}
                                </Typography>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={3}>
                            <Button
                              color="primary"
                              startIcon={
                                loading ? (
                                  <CircularProgress size="1rem" />
                                ) : null
                              }
                              disabled={loading}
                              type="submit"
                              size="large"
                              variant="contained"
                              style={{ color: "#fff", width: "120px" }}
                            >
                              {"Update"}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid>
            </>
          ) : (
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress style={{ marginRight: "12px" }} />
              <p>wait until fetching sms provider data</p>
            </Grid>
          )}
        </StyledWrapperCard>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Footer />
      </Grid>
    </Box>
  );
};

export default SmsGateway;

const StyledWrapperCard = styled(Card)`
  padding: 40px 40px !important;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 65%;
    padding: 50px 50px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    padding: 10px !important;
  }
`;
