import React from "react";
import { Routes as RoutesWrapper, Route, Navigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";

import DashboardLayout from "src/components/layouts/DashboardLayout";
import AuthLayout from "src/components/layouts/AuthLayout";

import ProtectedRoutes from "./ProtectedRoutes";

//-----------pages---------------//
import Login from "src/pages/auth/Login";
import ResetPassword from "src/pages/auth/ResetPassword";
import ForgotPassword from "src/pages/auth/ForgotPassword";

import AdminList from "src/pages/dashboard/users/Admins";
import CustomerList from "src/pages/dashboard/users/Customers";
import ProList from "src/pages/dashboard/users/Pro";
import MyProfile from "src/pages/dashboard/users/MyProfile";
import UserDetails from "src/pages/dashboard/users/Details";

import Category from "src/pages/dashboard/catalog/Category";
import AddUpdateCategory from "src/pages/dashboard/catalog/Category/AddUpdatePage";
import Services from "src/pages/dashboard/catalog/Service";
import AddUpdateService from "src/pages/dashboard/catalog/Service/AddUpdatePage";

import Questionnaire from "src/pages/dashboard/questionnaire/Questionnaire";
import AddUpdateQuestionnaire from "src/pages/dashboard/questionnaire/Questionnaire/AddUpdatePage";
import Questions from "src/pages/dashboard/questionnaire/Questions";
import AddUpdateQuestion from "src/pages/dashboard/questionnaire/Questions/AddUpdatePage";
import Options from "src/pages/dashboard/questionnaire/Options";
import AddUpdateOption from "src/pages/dashboard/questionnaire/Options/AddUpdatePage";

import BookRequests from "src/pages/dashboard/booking/BookRequests";
import BookRequestDetail from "src/pages/dashboard/booking/BookRequests/Detail";
import BidsList from "src/pages/dashboard/booking/Bids";
import BidDetail from "src/pages/dashboard/booking/Bids/Detail";
import PaymentHistory from "src/pages/dashboard/bidPricing/PaymentHistory";
import PaymentHistoryDetail from "src/pages/dashboard/bidPricing/PaymentHistory/Detail";

import WalletLimit from "src/pages/dashboard/system/Settings";
import AdminEmail from "src/pages/dashboard/system/AdminEmail";
import EditStaticPages from "src/pages/dashboard/system/EditStaticPages";
import UnitMeasurement from "src/pages/dashboard/system/UnitMeasurement";
import AddUpdateUnits from "src/pages/dashboard/system/UnitMeasurement/AddUpdatePage";
import InAppSubscription from "src/pages/dashboard/system/InAppSubscription";
import SmsGateway from "src/pages/dashboard/system/SmsGateway";

import Administration from "src/pages/dashboard/Administration";

import WelcomPage from "src/pages/dashboard/WelcomePage";

import NotFound from "src/pages/404";
import NotPermitted from "src/pages/NotPermitted";
import StaticPage from "src/pages/statics/StaticPage";
import { PERMISSIONS } from "src/constants/permissions";
//--------------------------------//

const AppRouter = () => {
  return (
    <RoutesWrapper>
      <Route path={ROUTE_CONSTANTS.ROOT.ABSOLUTE} element={<Login />} />
      <Route
        path={ROUTE_CONSTANTS.PUBLIC_ROUTES.NOT_PERMITTED.ABSOLUTE}
        element={<NotPermitted />}
      />
      <Route path={ROUTE_CONSTANTS.AUTH.ROOT.RELATIVE} element={<AuthLayout />}>
        <Route
          path=""
          element={<Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} />}
        />
        <Route path={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} element={<Login />} />
        <Route
          path={ROUTE_CONSTANTS.AUTH.RESET_PASSWORD.RELATIVE}
          element={<ResetPassword />}
        />
        <Route
          path={ROUTE_CONSTANTS.AUTH.FORGOT_PASSWORD.RELATIVE}
          element={<ForgotPassword />}
        />
      </Route>
      <Route
        path={ROUTE_CONSTANTS.DASHBOARD.ROOT.RELATIVE}
        element={<DashboardLayout />}
      >
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.WELCOME.ROOT.RELATIVE}
          element={<WelcomPage />}
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.USERS.PROFILE.RELATIVE}
          element={<MyProfile />}
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.USERS.ADMINS.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Amin_List}>
              <AdminList />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.USERS.CUSTOMERS.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Customer_List}>
              <CustomerList />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.USERS.PRO.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Pro_List}>
              <ProList />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.USERS.DETAILS.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_User_Detail}>
              <UserDetails />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.CATALOG.CATEGORY.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Category_List}>
              <Category />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.CATALOG.CATEGORY.REORDER.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Reorder_Categories}>
              <Category />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.CATALOG.CATEGORY.ADD_NEW.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Create_New_Category}>
              <AddUpdateCategory />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.CATALOG.CATEGORY.UPDATE.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Update_Category}>
              <AddUpdateCategory />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.CATALOG.TYPE.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Service_List}>
              <Services />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.CATALOG.TYPE.ADD_NEW.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Create_New_Service}>
              <AddUpdateService />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.CATALOG.TYPE.UPDATE.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Update_Service}>
              <AddUpdateService />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Questionnaire_List}>
              <Questionnaire />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.ADD_NEW.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Create_New_Questionnaire}>
              <AddUpdateQuestionnaire />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.UPDATE.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Update_Questionnaire}>
              <AddUpdateQuestionnaire />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Question_List}>
              <Questions />
            </ProtectedRoutes>
          }
        />
        <Route
          path={
            ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.REORDER.RELATIVE
          }
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Reorder_Questions}>
              <Questions />
            </ProtectedRoutes>
          }
        />
        <Route
          path={
            ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.ADD_NEW.RELATIVE
          }
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Create_New_Question}>
              <AddUpdateQuestion />
            </ProtectedRoutes>
          }
        />
        <Route
          path={
            ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.UPDATE.RELATIVE
          }
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Update_Question}>
              <AddUpdateQuestion />
            </ProtectedRoutes>
          }
        />
        <Route
          path={
            ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.ROOT
              .RELATIVE
          }
          element={
            <ProtectedRoutes
              accessKey={PERMISSIONS.Get_Options_Related_To_Question}
            >
              <Options />
            </ProtectedRoutes>
          }
        />
        <Route
          path={
            ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.REORDER
              .RELATIVE
          }
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Reorder_Options}>
              <Options />
            </ProtectedRoutes>
          }
        />
        <Route
          path={
            ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.ADD_NEW
              .RELATIVE
          }
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Create_New_Option}>
              <AddUpdateOption />
            </ProtectedRoutes>
          }
        />
        <Route
          path={
            ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.UPDATE
              .RELATIVE
          }
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Update_Option}>
              <AddUpdateOption />
            </ProtectedRoutes>
          }
        />

        <Route
          path={
            ROUTE_CONSTANTS.DASHBOARD.BIDPRICING.PAYMENTHISTORY.ROOT.RELATIVE
          }
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Payment_History_List}>
              <PaymentHistory />
            </ProtectedRoutes>
          }
        />

        <Route
          path={
            ROUTE_CONSTANTS.DASHBOARD.BIDPRICING.PAYMENTHISTORY.DETAILS.RELATIVE
          }
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Payment_Detail}>
              <PaymentHistoryDetail />
            </ProtectedRoutes>
          }
        />

        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.BOOKING.BOOKREQUESTS.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Book_Request_List}>
              <BookRequests />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.BOOKING.BOOKREQUESTS.DETAILS.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Book_Detail}>
              <BookRequestDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.BOOKING.BIDS.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Bid_List}>
              <BidsList />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.BOOKING.BIDS.DETAILS.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Bid_Detail}>
              <BidDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.SYSTEM.STATIC_PAGES.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Static_page}>
              <EditStaticPages />
            </ProtectedRoutes>
          } //?
        />

        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.SYSTEM.UNIY_MEASUREMENT.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Units}>
              <UnitMeasurement />
            </ProtectedRoutes>
          }
        />
        <Route
          path={
            ROUTE_CONSTANTS.DASHBOARD.SYSTEM.UNIY_MEASUREMENT.ADD_NEW.RELATIVE
          }
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Create_New_Unit}>
              <AddUpdateUnits />
            </ProtectedRoutes>
          }
        />
        <Route
          path={
            ROUTE_CONSTANTS.DASHBOARD.SYSTEM.UNIY_MEASUREMENT.UPDATE.RELATIVE
          }
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Update_Unit}>
              <AddUpdateUnits />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.SYSTEM.WALLETLIMIT.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Setting}>
              <WalletLimit />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.SYSTEM.ADMINEMAIL.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Setting}>
              <AdminEmail />
            </ProtectedRoutes>
          }
        />
        <Route
          path={
            ROUTE_CONSTANTS.DASHBOARD.SYSTEM.INAPPSUBSCRIPTION.ROOT.RELATIVE
          }
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Setting}>
              <InAppSubscription />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.SYSTEM.ADMINISTRATION.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Role_Controller}>
              <Administration />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.SYSTEM.SMS_GATEWAY.ROOT.RELATIVE}
          element={
            <ProtectedRoutes accessKey={PERMISSIONS.Get_Setting}>
              <SmsGateway />
            </ProtectedRoutes>
          }
        />
        <Route path="/dashboard/*" element={<Navigate to={"/404"} />} />
      </Route>
      <Route
        path={ROUTE_CONSTANTS.PUBLIC_ROUTES.STATIC_PAGES.ROOT.ABSOLUTE}
        element={<StaticPage />}
      />
      <Route path={ROUTE_CONSTANTS.ROOT_STAR.ABSOLUTE} element={<NotFound />} />
    </RoutesWrapper>
  );
};

export default AppRouter;
